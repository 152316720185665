import AuthService from "./AuthService";

export default class AudioService {
  static getAudio(call, contractId) {
    return new Promise((resolve, reject) => {
      AuthService.refreshToken().then(
        () => {
          return resolve(
            `${process.env.REACT_APP_API_PATH_URL}/audio?callDate=${
              call.recordDate
            }&contractId=${contractId}&callId=${call.callId}&agentId=${
              call.agentId
            }&jwtToken=${AuthService.getToken()}`,
          );
        },
        (error) => {
          reject(error);
        },
      );
    });
  }
}
