import React, { useState, useCallback, useEffect, useContext } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import ReactPlayer from "react-player";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Filter from "../components/Filter";
import CallTable from "../components/CallTable";
import CallService from "../services/CallService";
import AudioService from "../services/AudioService";
import TrackingService from "../services/TrackingService";
import MessageContext from "../components/MessageContext";

const useStyles = makeStyles((theme) => ({
  playerBar: {
    backgroundColor: theme.palette.primary.main,
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playerContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },
  button: {
    color: "white",
  },
  audioDetail: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    color: "white",
    paddingLeft: theme.spacing(1),
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

const Home = () => {
  const classes = useStyles();

  const [calls, setCalls] = useState([]);
  const [callsTotal, setCallsTotal] = useState(0);
  const [queries, setQueries] = useState({
    page: 1,
    rowsPerPage: 10,
    callDate: new Date(),
  });
  const [callActive, setCallActive] = useState(null);
  const [audioIsPlaying, setAudioIsPlaying] = useState(false);
  const [audioData, setAudioData] = useState(null);
  const [callTypes, setCallTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { displayError } = useContext(MessageContext);

  useEffect(() => {
    const getAllCallTypes = async () => {
      setCallTypes(await CallService.getAllCallTypes());
    };
    getAllCallTypes();
  }, []);

  const formatRecordDate = (call) => {
    return `${call.recordDate.substring(0, 4)}-${call.recordDate.substring(
      4,
      6,
    )}-${call.recordDate.substring(6, 8)}`
      .split("-")
      .reverse()
      .join("-");
  };

  const formatRecordTime = (call) => {
    const pad = (s) => {
      return s < 10 ? `0${s}` : s;
    };
    const recordDateSplitted = formatRecordDate(call).split("-");
    const recordTimeSplitted = call.recordTime.match(/[0-9]{2}/g);
    const newDate = new Date(
      Date.UTC(
        recordDateSplitted[2],
        recordDateSplitted[1],
        recordDateSplitted[0],
        recordTimeSplitted[0],
        recordTimeSplitted[1],
        recordTimeSplitted[2],
      ),
    );
    return `${pad(newDate.getHours())}:${pad(newDate.getMinutes())}:${pad(
      newDate.getSeconds(),
    )}`;
  };

  const handleAudioClose = () => {
    setCallActive(null);
    setAudioIsPlaying(false);
    setAudioData(null);
  };

  const getCalls = useCallback(
    async (newQueries) => {
      const queriesToUse = newQueries || queries;
      if (!queriesToUse) return;

      if (
        queriesToUse.callDateBegin.getTime() >
        queriesToUse.callDateEnd.getTime()
      ) {
        displayError(
          "La date de début doit être inférieure ou égale à la date de fin",
        );
      } else {
        setIsLoading(true);
        const result = await CallService.getAll({
          ...queriesToUse,
          callDate: queriesToUse.callDate.toISOString(),
        });

        if (Array.isArray(result)) {
          const [newCalls, total] = result;
          setCalls(
            newCalls.map((call) => ({
              ...call,
              recordDate: formatRecordDate(call),
              recordTime: formatRecordTime(call),
              callType:
                (callTypes.find((c) => c.callTypeId === call.callTypeId) || {})
                  .description || call.callType,
            })),
          );
          setCallsTotal(total);
          handleAudioClose();
        } else {
          displayError(result);
        }
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line
    [queries, callTypes, displayError],
  );

  const getAudio = async (call) => {
    const newAudioData = await AudioService.getAudio(
      call || callActive,
      queries.contractId,
    );
    setAudioData(newAudioData);
  };

  const handleQueriesChange = (newQueries) => {
    const result = { ...queries, ...newQueries };
    setQueries(result);
    getCalls(result);
  };

  const handleClickCallActive = async (call) => {
    if (!callActive || call.callId !== callActive.callId) {
      getAudio(call);
      setCallActive(call);
      setAudioIsPlaying(true);
    } else {
      setAudioIsPlaying(!audioIsPlaying);
    }
  };

  return (
    <>
      <Typography variant="h1" style={{ marginBottom: 32 }}>
        Appels
      </Typography>
      <Filter
        onChange={handleQueriesChange}
        setCalls={setCalls}
        defaultCallDate={queries.callDate}
        isLoading={isLoading}
      />
      <CallTable
        rows={calls}
        total={callsTotal}
        onChange={handleQueriesChange}
        setRowActive={handleClickCallActive}
        rowActive={callActive}
        audioIsPlaying={audioIsPlaying}
        isLoading={isLoading}
      />
      {callActive && (
        <div className={classes.playerBar}>
          <Container className={classes.playerContainer}>
            <div className={classes.audioDetail}>
              <span>
                {callActive.recordDate} {callActive.recordTime}
              </span>
              <span>ID du conseiller: {callActive.agentId}</span>
            </div>
            <ReactPlayer
              width="400px"
              height="50px"
              style={{ margin: "0 auto", maxWidth: "100%" }}
              url={audioData}
              controls
              playing={audioIsPlaying}
              onPlay={() => setAudioIsPlaying(true)}
              onPause={() => setAudioIsPlaying(false)}
              onStart={() => TrackingService.createTracking(callActive)}
              onError={() =>
                displayError("Un problème est survenu avec le fichier audio")
              }
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                  forceAudio: true,
                },
              }}
            />
            <IconButton
              className={classes.button}
              onClick={() => handleAudioClose()}
            >
              <CloseIcon />
            </IconButton>
          </Container>
        </div>
      )}
    </>
  );
};

export default Home;
