const ROUTING = [
  {
    path: "/",
    key: "home",
  },
  {
    path: "/contracts",
    key: "contracts",
  },
];

export default ROUTING;
