import React, { useState, useEffect, Fragment } from "react";
import { Router, Switch, Route } from "react-router-dom";
import history from "./history";
import ROUTING from "./routes";
import Layout from "../layouts/Layout";
import Home from "../pages/Home";
import Contracts from "../pages/Contracts";
import P404 from "./P404";
import AuthService from "../services/AuthService";

const Routes = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const logged = await AuthService.checkAccess();
      if (logged) {
        setLoading(false);
      } else {
        console.error("Erreur lors de la connexion");
      }
    };
    checkAuth();
  }, []);

  const getRouteComponent = (route) => {
    switch (route.key) {
      case "home":
        return Home;
      case "contracts":
        return Contracts;
      default:
        break;
    }
    return null;
  };

  const renderRoute = (route) => {
    const component = getRouteComponent(route);
    if (!component) {
      return null;
    }
    if (loading) {
      return <Fragment key="loading"> </Fragment>;
    }
    return <Route exact component={component} {...route} />;
  };

  const Wrapper = loading ? Fragment : Layout;

  return (
    <Router history={history}>
      <Wrapper>
        <Switch>
          {ROUTING.map(renderRoute)}
          <Route component={P404} />
        </Switch>
      </Wrapper>
    </Router>
  );
};

export default Routes;
