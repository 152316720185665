import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import MuiTextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}));

const TextField = (props) => {
  const { id, label, value, setValue, endIcon, ...others } = props;
  const classes = useStyles();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Box display="flex" alignItems="end" justifyContent="space-between">
      <MuiTextField
        className={classes.root}
        id={id}
        label={label}
        value={value}
        onChange={handleChange}
        {...others}
      />
      {endIcon}
    </Box>
  );
};

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  endIcon: PropTypes.element,
};

TextField.defaultProps = {
  label: "",
  value: "",
  endIcon: null,
  setValue: () => {},
};

export default TextField;
