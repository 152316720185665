import axios from "axios";

export default class CallService {
  static getAll(queries) {
    const queriesFormated = Object.entries(queries).reduce(
      (str, [key, value], index) => {
        return `${str}${key}=${value}${
          index < Object.keys(queries).length - 1 ? "&" : ""
        }`;
      },
      "",
    );

    return axios
      .get(`${process.env.REACT_APP_API_PATH_URL}/call?${queriesFormated}`)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return "Une erreur est survenue";
      });
  }

  static getAllCallTypes() {
    return axios
      .get(`${process.env.REACT_APP_API_PATH_URL}/call/calltypes`)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  }
}
