import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MuiSelect from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}));

const Select = (props) => {
  const { id, label, value, setValue, menuItems, required } = props;
  const classes = useStyles();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl className={classes.root}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <MuiSelect
        labelId={`${id}-label`}
        id={id}
        value={value}
        onChange={handleChange}
        required={required}
      >
        {menuItems &&
          menuItems.map((menuItem) => (
            <MenuItem key={menuItem.value} value={menuItem.value}>
              {menuItem.label}
            </MenuItem>
          ))}
      </MuiSelect>
    </FormControl>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
  required: PropTypes.bool,
};

Select.defaultProps = {
  label: "",
  value: "",
  menuItems: [],
  required: false,
};

export default Select;
