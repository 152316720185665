import axios from "axios";

export default class TrackingService {
  static createTracking(call) {
    return axios
      .post(`${process.env.REACT_APP_API_PATH_URL}/tracking`, {
        callId: call.callId,
        convDuration: call.convDuration,
        callTypeId: call.callTypeId,
        agentId: call.agentId,
        sda: call.sda,
        customerId: call.customerId,
      })
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return null;
      });
  }
}
