import React, { useState, useEffect, useReducer } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Axios from "axios";
import AuthService from "./services/AuthService";
import BootstrapService from "./services/BootstrapService";
import MessageContext from "./components/MessageContext";
import messageTypes from "./utils/messageTypes";
import Theme from "./layouts/Theme";
import Router from "./routes/Router";

const useStyles = makeStyles(() => ({
  loadingContainer: {
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
}));

const snackbarReducer = (snackbarState, action) => {
  const { type, message } = action || {};
  switch (type) {
    case messageTypes.SUCCESS:
      return {
        open: true,
        duration: 5000,
        variant: "success",
        message,
      };
    case messageTypes.ERROR:
      return {
        open: true,
        duration: 5000,
        variant: "error",
        message,
      };
    default:
      return {
        ...snackbarState,
        open: false,
        duration: 5000,
      };
  }
};

const App = () => {
  const classes = useStyles();
  const [initializing, setInitializing] = useState(true);
  const [snackbar, dispatch] = useReducer(snackbarReducer, { open: false });

  Axios.interceptors.request.use(
    async (config) => {
      await AuthService.refreshToken();
      const configuration = config;
      configuration.headers.authorization = `Bearer ${AuthService.getToken()}`;
      return configuration;
    },
    (error) => Promise.reject(error),
  );

  Axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const { response } = error;
      if (response && response.status === 401) {
        AuthService.logout();
      }
      return Promise.reject(error);
    },
  );

  useEffect(() => {
    const initialize = async () => {
      await BootstrapService.before();
      setInitializing(false);
    };
    initialize();
  }, []);

  const displaySuccess = (message) => {
    dispatch({ type: messageTypes.SUCCESS, message });
  };

  const displayError = (message) => {
    dispatch({ type: messageTypes.ERROR, message });
  };

  const handleCloseSnackbar = () => {
    dispatch();
  };

  return (
    <Theme>
      <CssBaseline />
      {initializing ? (
        <div className={classes.loadingContainer}>
          <CircularProgress color="primary" />
          <Typography>Chargement du système d&apos;authentification</Typography>
        </div>
      ) : (
        <>
          <MessageContext.Provider
            value={{
              displaySuccess,
              displayError,
            }}
          >
            <Router />
          </MessageContext.Provider>
          <Snackbar
            {...snackbar}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleCloseSnackbar}
              severity={snackbar.variant || "error"}
            >
              {snackbar.message}
            </MuiAlert>
          </Snackbar>
        </>
      )}
    </Theme>
  );
};

export default App;
