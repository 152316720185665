import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/SearchRounded";
import IconButton from "@material-ui/core/IconButton";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  input: {
    border: `1px solid ${theme.palette.text.primary}`,
    padding: "0.85rem",
    paddingRight: theme.spacing(0.5),
    lineHeight: "1.285rem",
    width: "100%",
    maxWidth: 400,
    borderRadius: 5,
    backgroundColor: "transparent",
    "&:focus": {
      borderColor: theme.palette.primary.main,
      outline: "none",
    },
    "& .MuiInputBase-input": {
      padding: 0,
    },
    "& .MuiIconButton-root": {
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
    },
  },
  borderLess: {
    background: "#fff",
    border: "none",
  },
}));

const SearchBar = (props) => {
  const { id, label, setValue, borderLess, ...others } = props;
  const classes = useStyles();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Input
      inputProps={{
        "aria-label": "Barre de recherche",
      }}
      role="combobox"
      variant="oulined"
      aria-expanded="false"
      className={classnames(
        classes.input,
        borderLess ? classes.borderLess : "",
      )}
      disableUnderline
      placeholder="Recherche"
      endAdornment={
        <InputAdornment position="end">
          <IconButton aria-label="Bouton de recherche">
            <Search />
          </IconButton>
        </InputAdornment>
      }
      fullWidth
      onChange={handleChange}
      {...others}
    />
  );
};

SearchBar.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  setValue: PropTypes.func,
  borderLess: PropTypes.bool,
};

SearchBar.defaultProps = {
  id: "",
  label: "",
  setValue: () => {},
  borderLess: false,
};

export default SearchBar;
