import React, { useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import { ModalActions } from "components/Modal";
import TextField from "components/TextField";
import AddIcon from "@material-ui/icons/Add";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  name: yup
    .string()
    .required("Un nom de contrat doit être saisi")
    .max(250, "Le nom est trop long"),
  customerId: yup
    .number()
    .typeError("L'identifiant client doit être un nombre")
    .required("Un identifiant client doit être saisi"),
});

const useStyles = makeStyles((theme) => ({
  chip: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  addIcon: {
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0 4px 4px 0",
    color: "white",
    cursor: "pointer",
  },
  chipInput: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px 0 0 4px",
    },
  },
}));

const ContractForm = (props) => {
  const { contract, onSubmit, onCancel } = props;
  const classes = useStyles();

  const [formData, setFormData] = useState({
    name: contract?.name || "",
    customerId:
      contract && contract.customerId ? String(contract?.customerId) : "",
  });
  const [sdaValue, setSdaValue] = useState();
  const [sdas, setSdas] = useState(contract?.sdas.map((sda) => sda.name) || []);
  const [hasAlreadySda, setHasAlreadySda] = useState(false);
  const [agentExcludeValue, setAgentExcludeValue] = useState();
  const [agentsExclude, setAgentsExclude] = useState(
    contract?.agentsExclude || [],
  );
  const [hasAlreadyAgentExclude, setHasAlreadyAgentExclude] = useState(false);

  const handleSdaDelete = (sdaToRemove) => {
    setSdas(sdas.filter((sda) => sda !== sdaToRemove));
  };

  const handleSdaAdd = () => {
    setHasAlreadySda(false);
    if (sdaValue) {
      if (sdas.find((sda) => sda.toLowerCase() === sdaValue.toLowerCase())) {
        setHasAlreadySda(true);
      } else {
        setSdas([...sdas, sdaValue]);
        setSdaValue("");
      }
    }
  };

  const handleAgentExcludeDelete = (agentToRemove) => {
    setAgentsExclude(
      agentsExclude.filter((agentExclude) => agentExclude !== agentToRemove),
    );
  };

  const handleAgentExcludeAdd = () => {
    setHasAlreadyAgentExclude(false);
    if (agentExcludeValue && Number(agentExcludeValue)) {
      if (
        agentsExclude.find(
          (agentExclude) => agentExclude === Number(agentExcludeValue),
        )
      ) {
        setHasAlreadyAgentExclude(true);
      } else {
        setAgentsExclude([...agentsExclude, Number(agentExcludeValue)]);
        setAgentExcludeValue("");
      }
    }
  };

  return (
    <Formik
      initialValues={formData}
      onSubmit={(values) => {
        setFormData(values);
        onSubmit({ ...values, sdas, agentsExclude });
      }}
      validationSchema={validationSchema}
    >
      {({ errors, touched, handleSubmit }) => (
        <Form>
          <Box p={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Field
                  id="name"
                  name="name"
                  label="Nom du contrat"
                  as={TextField}
                  error={!!(touched.name && errors.name)}
                  FormHelperTextProps={{
                    error: !!(touched.name && errors.name),
                    variant: "standard",
                  }}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  id="customerId"
                  name="customerId"
                  label="Identifiant du client"
                  as={TextField}
                  error={!!(touched.customerId && errors.customerId)}
                  FormHelperTextProps={{
                    error: !!(touched.customerId && errors.customerId),
                    variant: "standard",
                  }}
                  helperText={touched.customerId && errors.customerId}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="sdas"
                  label="Campagnes / SDA"
                  className={classes.chipInput}
                  variant="outlined"
                  value={sdaValue}
                  setValue={setSdaValue}
                  endIcon={
                    <div
                      className={classes.addIcon}
                      onClick={() => handleSdaAdd()}
                    >
                      <AddIcon />
                    </div>
                  }
                  error={hasAlreadySda}
                  FormHelperTextProps={{
                    error: hasAlreadySda,
                    variant: "standard",
                  }}
                  helperText={
                    hasAlreadySda &&
                    "Cette campagne / sda a déjà été ajoutée au contrat"
                  }
                />
                {sdas.sort().map((sda) => (
                  <Chip
                    key={sda}
                    label={sda}
                    onDelete={() => handleSdaDelete(sda)}
                    className={classes.chip}
                  />
                ))}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="agentsExclude"
                  label="Conseillers exclus"
                  className={classes.chipInput}
                  variant="outlined"
                  value={agentExcludeValue}
                  setValue={setAgentExcludeValue}
                  endIcon={
                    <div
                      className={classes.addIcon}
                      onClick={() => handleAgentExcludeAdd()}
                    >
                      <AddIcon />
                    </div>
                  }
                  error={hasAlreadyAgentExclude}
                  FormHelperTextProps={{
                    error: hasAlreadyAgentExclude,
                    variant: "standard",
                  }}
                  helperText={
                    hasAlreadyAgentExclude &&
                    "Ce conseiller a déjà été exclu du contrat"
                  }
                />
                {agentsExclude
                  .sort((a, b) => a - b)
                  .map((agentExclude) => (
                    <Chip
                      key={agentExclude}
                      label={agentExclude}
                      onDelete={() => handleAgentExcludeDelete(agentExclude)}
                      className={classes.chip}
                    />
                  ))}
              </Grid>
            </Grid>
            <ModalActions>
              <Button color="secondary" onClick={onCancel}>
                Annuler
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {contract ? "Modifier" : "Ajouter"}
              </Button>
            </ModalActions>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

ContractForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  contract: PropTypes.shape(),
};

ContractForm.defaultProps = {
  contract: null,
};

export default ContractForm;
