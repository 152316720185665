import axios from "axios";

export default class UserService {
  static getUsersByContracts() {
    return axios
      .get(
        `${process.env.REACT_APP_KEYCLOAK_URL}admin/realms/${process.env.REACT_APP_KEYCLOAK_RESOURCE}/users/count`,
      )
      .then((response) => {
        const nbUser = response.data;
        return axios
          .get(
            `${process.env.REACT_APP_KEYCLOAK_URL}admin/realms/${process.env.REACT_APP_KEYCLOAK_RESOURCE}/users`,
            {
              params: {
                max: nbUser,
              },
            },
          )
          .then((result) => {
            return result.data;
          })
          .catch(() => {
            return [];
          });
      })
      .catch(() => {
        return [];
      });
  }

  static updateUserContract(id, user, contracts) {
    const userToUpdate = { ...user };
    delete userToUpdate.contracts;
    delete userToUpdate.socialReason;

    return axios
      .put(
        `${process.env.REACT_APP_KEYCLOAK_URL}admin/realms/${process.env.REACT_APP_KEYCLOAK_RESOURCE}/users/${id}`,
        {
          ...userToUpdate,
          attributes: { ...userToUpdate.attributes, contracts: [contracts] },
        },
      )
      .then((response) => {
        return response.data;
      });
  }
}
