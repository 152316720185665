import axios from "axios";

export default class ContractService {
  static getAll(page, limit) {
    return axios
      .get(`${process.env.REACT_APP_API_PATH_URL}/contracts`, {
        params: {
          limit,
          start: (page - 1) * limit,
        },
      })
      .then((response) => {
        const { datas, total } = response.data;
        return [datas, total];
      })
      .catch(() => {
        return [];
      });
  }

  static getAssigned() {
    return axios
      .get(`${process.env.REACT_APP_API_PATH_URL}/contracts/assigned`)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  }

  static addContract(name, customerId, sdas, agentsExclude) {
    return axios
      .post(`${process.env.REACT_APP_API_PATH_URL}/contracts`, {
        name,
        customerId,
        sdas,
        agentsExclude,
      })
      .then((response) => {
        return response.data;
      });
  }

  static editContract(id, name, customerId, sdas, agentsExclude) {
    return axios
      .put(`${process.env.REACT_APP_API_PATH_URL}/contracts/${id}`, {
        name,
        customerId,
        sdas,
        agentsExclude,
      })
      .then((response) => {
        return response.data;
      });
  }

  static removeContract(id) {
    return axios
      .delete(`${process.env.REACT_APP_API_PATH_URL}/contracts/${id}`)
      .then((response) => {
        return response.data;
      });
  }
}
