import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}));

const DatePicker = (props) => {
  const { id, label, value, setValue } = props;
  const classes = useStyles();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <TextField
      className={classes.root}
      id={id}
      label={label}
      type="date"
      value={value}
      onChange={handleChange}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};

DatePicker.defaultProps = {
  label: "",
  value: "",
};

export default DatePicker;
