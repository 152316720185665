import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import IconButton from "@material-ui/core/IconButton";
import Loader from "components/Loader";

const columns = [
  {
    id: "agentId",
    label: "ID du conseiller",
  },
  {
    id: "recordDate",
    label: "Date de l’appel",
  },
  {
    id: "recordTime",
    label: "Heure de l’appel",
  },
  {
    id: "callType",
    label: "Sens de l’appel",
  },
  {
    id: "convDuration",
    label: "Durée de l’appel",
    format: (convDuration) =>
      new Date(convDuration * 1000).toISOString().substr(11, 8),
  },
  {
    id: "callStatus",
    label: "Qualification de l’appel",
  },
  {
    id: "idLink",
    label: "ID de l’appel",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  paper: {
    width: "100%",
    marginTop: theme.spacing(4),
    "& thead": {
      backgroundColor: theme.palette.primary.main,
      "& th": {
        color: "white",
      },
    },
  },
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const CallTable = (props) => {
  const {
    rows,
    total,
    onChange,
    rowActive,
    setRowActive,
    audioIsPlaying,
    isLoading,
  } = props;
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("recordTime");

  const handleChangePage = (event, newPage) => {
    if (isLoading) return;
    setPage(newPage + 1);
    onChange({ page: newPage + 1, rowsPerPage, order, orderBy });
  };

  const handleChangeRowsPerPage = (event) => {
    if (isLoading) return;
    setRowsPerPage(event.target.value);
    setPage(1);
    onChange({ page: 1, rowsPerPage: event.target.value, order, orderBy });
  };

  const rowIsActive = (row) => rowActive && rowActive.callId === row.callId;

  const onRequestSort = (property) => {
    if (isLoading) return;
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    onChange({ order: newOrder, orderBy: property });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} style={{ opacity: isLoading && 0.5 }}>
        <TableContainer>
          <MuiTable>
            <TableHead>
              <TableRow>
                <TableCell />
                {columns.map((column) => (
                  <TableCell key={column.id}>
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={() => onRequestSort(column.id)}
                      style={{ color: "white" }}
                      disabled={isLoading}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow
                    hover
                    selected={rowIsActive(row)}
                    role="checkbox"
                    tabIndex={-1}
                    key={`${row.callId}-${row.agentId}`}
                  >
                    <TableCell>
                      <IconButton
                        color="secondary"
                        onClick={() => setRowActive(row)}
                        disabled={isLoading}
                      >
                        {rowIsActive(row) && audioIsPlaying ? (
                          <PauseCircleFilledIcon />
                        ) : (
                          <PlayCircleFilledIcon />
                        )}
                      </IconButton>
                    </TableCell>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          className={column.classe && classes[column.classe]}
                          key={column.id}
                          align={column.align}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </MuiTable>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Nombre d'appels par page"
          backIconButtonText="Page précédente"
          nextIconButtonText="Page suivante"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} sur ${count !== -1 ? count : to} appels`
          }
        />
      </Paper>
      {isLoading && (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
    </div>
  );
};

CallTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func.isRequired,
  setRowActive: PropTypes.func.isRequired,
  rowActive: PropTypes.shape(),
  audioIsPlaying: PropTypes.bool,
  total: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
};

CallTable.defaultProps = {
  rows: [],
  rowActive: {},
  audioIsPlaying: false,
  total: null,
};

export default CallTable;
