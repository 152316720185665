import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "./Select";
import TextField from "./TextField";
import DatePicker from "./DatePicker";
import ContractService from "../services/ContractService";

const Filter = (props) => {
  const { defaultCallDate, onChange, isLoading } = props;
  const [contractId, setContractId] = useState("");
  const [callDateBegin, setCallDateBegin] = useState(
    defaultCallDate.toISOString().split("T")[0],
  );
  const [callDateEnd, setCallDateEnd] = useState(
    defaultCallDate.toISOString().split("T")[0],
  );
  const [contracts, setContracts] = useState([]);
  const [agentId, setAgentId] = useState("");

  const getContracts = useCallback(async () => {
    const result = await ContractService.getAssigned();
    const contractsFinded = result.map((contract) => ({
      label: contract.name,
      value: String(contract.id),
    }));
    setContracts(contractsFinded);

    if (contractsFinded.length === 1) {
      setContractId(contractsFinded[0].value);
    }
  }, []);

  useEffect(() => {
    getContracts();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    onChange({
      contractId,
      callDateBegin: new Date(callDateBegin),
      callDateEnd: new Date(callDateEnd),
      agentId: Number(agentId) || null,
    });
  };

  const onKeyPressTypeNumber = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/[0-9]/.test(keyValue)) event.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={4} md={3}>
          <Select
            id="contract"
            label="Contrat *"
            value={contractId}
            setValue={setContractId}
            menuItems={contracts}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <DatePicker
            id="callDateBegin"
            label="Date de début *"
            value={callDateBegin}
            setValue={setCallDateBegin}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <DatePicker
            id="callDateEnd+"
            label="Date de fin *"
            value={callDateEnd}
            setValue={setCallDateEnd}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            id="agentId"
            label="ID du conseiller"
            value={agentId}
            setValue={setAgentId}
            pattern="[0-9]*"
            onKeyPress={onKeyPressTypeNumber}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={isLoading}
          >
            Rechercher
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

Filter.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultCallDate: PropTypes.instanceOf(Date).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Filter;
