import {
  KEYCLOAK_PARAMS,
  REFRESH_TOKEN_TIME,
  VIEW_CONTRACTS,
} from "utils/keycloak";
import history from "routes/history";

let keycloak = null;
let refreshPromise = null;

export default class AuthService {
  static checkAccess = async () => {
    // eslint-disable-next-line no-undef
    keycloak = new Keycloak(KEYCLOAK_PARAMS);

    if (!keycloak.authenticated) {
      try {
        await keycloak.init({
          onLoad: "login-required",
        });
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    }
    return true;
  };

  static getProfile = async () => {
    await keycloak.loadUserProfile();
    return keycloak.profile;
  };

  static logout = async () => {
    keycloak.logout();
  };

  static getToken = () => {
    return keycloak.token;
  };

  static getUserId = () => {
    return keycloak.subject;
  };

  static createAccountUrl = () => {
    return keycloak.createAccountUrl();
  };

  static refreshToken = () => {
    if (!refreshPromise) {
      refreshPromise = new Promise((resolve, reject) => {
        if (!keycloak || !keycloak.authenticated) {
          reject(new Error("Unable to find authenticated user"));
        } else {
          keycloak
            .updateToken(REFRESH_TOKEN_TIME)
            .success(() => {
              refreshPromise = null;
              resolve();
            })
            .error(() => {
              reject(new Error("Unable to refresh token"));
            });
        }
      });
    }
    return refreshPromise;
  };

  static getRoles = async () => {
    const roles = keycloak.realmAccess && keycloak.realmAccess.roles;
    if (
      history.location.pathname === "/contracts" &&
      !roles.includes(VIEW_CONTRACTS)
    ) {
      history.push("/");
    }
    return roles || [];
  };
}
