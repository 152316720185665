import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import MuiModal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";

const useActionsStyles = makeStyles((theme) => ({
  actions: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "flex-end",
    "&> *": {
      marginLeft: theme.spacing(1),
    },
  },
}));

export const ModalActions = (props) => {
  const classes = useActionsStyles();

  return <div className={classes.actions} {...props} />;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  main: (props) => ({
    width: `${
      props.size && theme.breakpoints.values[props.size]
        ? `${theme.breakpoints.values[props.size]}px`
        : "auto"
    }`,
    maxWidth: `100%`,
    maxHeight: "100%",
    overflow: "auto",
  }),
  title: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.main,
    "& > *": {
      fontWeight: "bold",
      color: "white",
    },
  },
  backButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.primary,
  },
}));

const Modal = (props) => {
  const {
    children,
    onClose,
    size,
    title,
    padding,
    backFunc,
    ...others
  } = props;

  const classes = useStyles({ size });

  return (
    <MuiModal
      onClose={onClose}
      {...others}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={classes.wrapper} onClick={onClose}>
        <Paper
          variant="outlined"
          onClick={(e) => e.stopPropagation()}
          className={classes.main}
        >
          <Box p={2} className={classes.title}>
            {backFunc && (
              <IconButton
                className={classes.backButton}
                onClick={backFunc}
                size="small"
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography variant="h2">{title}</Typography>
          </Box>
          <Box p={padding}>{children}</Box>
        </Paper>
      </div>
    </MuiModal>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  padding: PropTypes.number,
  backFunc: PropTypes.func,
};

Modal.defaultProps = {
  title: "",
  className: "",
  children: null,
  size: null,
  padding: 2,
  backFunc: null,
};

export default Modal;
