import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Link, useLocation } from "react-router-dom";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import HearingOutlinedIcon from "@material-ui/icons/HearingOutlined";
import AuthService from "services/AuthService";
import HeaderLink from "components/HeaderLink";
import { VIEW_CONTRACTS } from "utils/keycloak";

const MAX_WIDTH = "lg";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  root: {
    marginTop: 88,
    marginBottom: 68 + theme.spacing(3),
  },
  logo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textDecoration: "none",
    "& img": {
      width: "6rem",
      marginRight: theme.spacing(2),
    },
  },
  avatar: {
    padding: 0,
  },
  list: {
    "& li": {
      textAlign: "center",
      justifyContent: "center",
    },
  },
  profile: {
    display: "flex",
    alignItems: "center",
  },
  profileName: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  links: {
    display: "flex",
    alignItems: "center",
    "& > *": {
      marginLeft: theme.spacing(7),
    },
  },
}));

const Layout = (props) => {
  const { children } = props;
  const classes = useStyles();
  const [profile, setProfile] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [roles, setRoles] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const getProfile = async () => {
      setProfile(await AuthService.getProfile());
    };
    const getRoles = async () => {
      setRoles(await AuthService.getRoles());
    };
    getProfile();
    getRoles();
  }, []);

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuLinkIsActive = (path) => {
    return location.pathname === path;
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <AppBar className={classes.header}>
        <Container maxWidth={MAX_WIDTH}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link to="/" className={classes.logo}>
              <img src="./logo_docaposte.svg" alt="Logo Docaposte" />
              CALL MONITORING
            </Link>

            <div className={classes.links}>
              {roles.includes(VIEW_CONTRACTS) && (
                <HeaderLink
                  to="/"
                  label="Appels"
                  Icon={HearingOutlinedIcon}
                  active={menuLinkIsActive("/")}
                />
              )}
              {roles.includes(VIEW_CONTRACTS) && (
                <HeaderLink
                  to="/contracts"
                  label="Contrats"
                  Icon={AssignmentOutlinedIcon}
                  active={menuLinkIsActive("/contracts")}
                />
              )}

              {profile ? (
                <div className={classes.profile}>
                  <Typography component="span" className={classes.profileName}>
                    {profile.firstName} {profile.lastName}
                  </Typography>
                  <IconButton
                    className={classes.avatar}
                    onClick={handleProfileClick}
                    color="secondary"
                  >
                    <AccountCircleIcon fontSize="large" />
                  </IconButton>

                  <Popover
                    id={open ? "profile-popover" : undefined}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <List className={classes.list} dense>
                      <ListItem>
                        <a
                          href={AuthService.createAccountUrl()}
                          style={{ textDecoration: "none" }}
                        >
                          <Button color="secondary">Modifier son compte</Button>
                        </a>
                      </ListItem>
                      <ListItem>
                        <Button onClick={AuthService.logout} color="secondary">
                          Se déconnecter
                        </Button>
                      </ListItem>
                    </List>
                  </Popover>
                </div>
              ) : (
                ""
              )}
            </div>
          </Box>
        </Container>
      </AppBar>
      <Container maxWidth={MAX_WIDTH} className={classes.root}>
        {children}
      </Container>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
