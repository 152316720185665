import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";

const Loader = (props) => {
  const { color, ...others } = props;

  return <CircularProgress style={{ color }} {...others} />;
};

Loader.propTypes = {
  color: PropTypes.string,
};

Loader.defaultProps = {
  color: null,
};

export default Loader;
