import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";

const columns = [
  {
    id: "name",
    label: "Nom",
    format: (name) => (
      <Box component="span" fontWeight="bold">
        {name}
      </Box>
    ),
  },
  {
    id: "customerId",
    label: "Identifiant du client",
    format: (customerId) => (
      <Box component="span" fontWeight="bold">
        {customerId}
      </Box>
    ),
    align: "right",
    maxWidth: 150,
  },
  {
    id: "sdas",
    label: "Nombre de campagnes / SDA",
    format: (sdas) => sdas.length,
    align: "right",
    maxWidth: 180,
  },
  {
    id: "users",
    label: "Utilisateurs assignés",
    format: (users) => users.length,
    align: "right",
    maxWidth: 150,
  },
  {
    id: "agentsExclude",
    label: "Conseillers exclus",
    format: (agentsExclude) => agentsExclude.length,
    align: "right",
    maxWidth: 150,
  },
  {
    id: "updated",
    label: "Date de dernière mise à jour",
    format: (updated) => {
      const date = new Date(updated);
      const pad = (s) => {
        return s < 10 ? `0${s}` : s;
      };
      return `${[
        pad(date.getDate()),
        pad(date.getMonth() + 1),
        date.getFullYear(),
      ].join("-")} ${pad(date.getHours())}:${pad(date.getMinutes())}`;
    },
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(4),
    "& thead": {
      backgroundColor: theme.palette.primary.main,
      "& th": {
        color: "white",
      },
    },
  },
}));

const ContractTable = (props) => {
  const { rows, total, onChange, onEdit, onRemove, onUserAssigned } = props;
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    onChange({ page: newPage + 1, limit: rowsPerPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
    onChange({ page: 1, limit: event.target.value });
  };

  return (
    <Paper className={classes.root}>
      <TableContainer>
        <MuiTable>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align || "left"}
                  style={{ maxWidth: column.maxWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  className={classes.row}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        className={column.classe && classes[column.classe]}
                        key={column.id}
                        align={column.align}
                        style={{ maxWidth: column.maxWidth }}
                      >
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                  <TableCell key="actions" align="right">
                    <IconButton
                      color="secondary"
                      onClick={() => onUserAssigned(row)}
                      title="Assigner des utilisateurs au contrat"
                    >
                      <GroupAddIcon size="16" />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => onEdit(row)}
                      title="Modifier le contrat"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => onRemove(row)}
                      title="Supprimer le contrat"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Nombre de contrats par page"
        backIconButtonText="Page précédente"
        nextIconButtonText="Page suivante"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} sur ${count !== -1 ? count : to} contrats`
        }
      />
    </Paper>
  );
};

ContractTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onUserAssigned: PropTypes.func.isRequired,
  total: PropTypes.number,
};

ContractTable.defaultProps = {
  rows: [],
  total: null,
};

export default ContractTable;
