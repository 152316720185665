import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from "components/Link";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      color: "rgba(0,0,140,0.70)",
      "& > *": {
        color: "rgba(0,0,140,0.70)",
      },
    },
    "&.active": {
      color: theme.palette.primary.main,
      "& > *": {
        color: theme.palette.primary.main,
      },
    },
  },
  label: {
    marginLeft: theme.spacing(1.5),
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  avatar: {
    padding: 0,
  },
  list: {
    "& li": {
      textAlign: "center",
      justifyContent: "center",
    },
  },
}));

const HeaderLink = (props) => {
  const { Icon, label, to, active, ...others } = props;
  const classes = useStyles();

  return (
    <Link
      underline="none"
      className={classnames(classes.link, active && "active")}
      to={to}
      {...others}
    >
      <Icon className={classes.icon} />{" "}
      <span className={classes.label}>{label}</span>
    </Link>
  );
};

HeaderLink.propTypes = {
  Icon: PropTypes.shape(),
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

HeaderLink.defaultProps = {
  Icon: null,
  active: false,
};

export default HeaderLink;
