import React, { useState, useEffect, useContext } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ContractService from "services/ContractService";
import ContractTable from "components/ContractTable";
import MessageContext from "components/MessageContext";
import Modal, { ModalActions } from "components/Modal";
import UserService from "services/UserService";
import ContractForm from "components/ContractForm";
import UserContractForm from "components/UserContractForm";
import contractActionUtils from "utils/contractActionUtils";

const Contracts = () => {
  const [contracts, setContracts] = useState([]);
  const [contractsTotal, setContractsTotal] = useState(0);
  const [queries, setQueries] = useState({
    page: 1,
    limit: 10,
  });
  const [displayModal, setDisplayModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSize, setModalSize] = useState("sm");
  const [actionType, setActionType] = useState("");
  const [contractSelected, setContractSelected] = useState();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { displaySuccess, displayError } = useContext(MessageContext);

  const getUsers = async () => {
    const newUsers = ((await UserService.getUsersByContracts()) || []).map(
      (user) => ({
        ...user,
        socialReason:
          (user.attributes &&
            user.attributes.social_reason &&
            user.attributes.social_reason.length > 0 &&
            user.attributes.social_reason[0]) ||
          "",
        contracts:
          (user.attributes &&
            user.attributes.contracts &&
            user.attributes.contracts.length > 0 &&
            user.attributes.contracts[0] &&
            user.attributes.contracts[0].split(",")) ||
          [],
      }),
    );
    setUsers(newUsers);
    return newUsers;
  };

  const getContracts = async (newQueries, forceRefreshUsers = false) => {
    const filters = newQueries || queries;
    const usersAssigned =
      forceRefreshUsers || !users || users.length === 0
        ? await getUsers()
        : users;
    const contractsFinded = await ContractService.getAll(
      filters.page,
      filters.limit,
    );
    setContracts(
      (contractsFinded &&
        contractsFinded[0] &&
        contractsFinded[0].map((contract) => ({
          ...contract,
          users: usersAssigned.filter((user) =>
            user.contracts.includes(String(contract.id)),
          ),
        }))) ||
        [],
    );
    setContractsTotal(
      (contractsFinded && contractsFinded[1] && contractsFinded[1]) || 0,
    );
  };

  useEffect(() => {
    getContracts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQueriesChange = (newQueries) => {
    const result = { ...queries, ...newQueries };
    setQueries(result);
    getContracts(result);
  };

  const renderModal = () => {
    switch (actionType) {
      case contractActionUtils.SAVE_CONTRACT:
        return (
          <ContractForm
            contract={contractSelected}
            onSubmit={async (values) => {
              const { name, customerId, sdas, agentsExclude } = values;
              try {
                if (contractSelected) {
                  await ContractService.editContract(
                    contractSelected.id,
                    name,
                    customerId,
                    sdas,
                    agentsExclude,
                  );
                  displaySuccess("Le contrat a été mis à jour");
                } else {
                  await ContractService.addContract(
                    name,
                    customerId,
                    sdas,
                    agentsExclude,
                  );
                  displaySuccess("Le contrat a été créé");
                }
              } catch (e) {
                displayError(
                  contractSelected
                    ? "Une erreur est survenue lors de la modification du contrat"
                    : "Une erreur est survenue lors de l'ajout du contrat",
                );
              }
              setDisplayModal(false);
              await getContracts();
            }}
            onCancel={() => {
              setDisplayModal(false);
            }}
          />
        );
      case contractActionUtils.REMOVE_CONTRACT:
        return (
          <Box p={2}>
            <Typography variant="body1">
              Êtes-vous sûrs de vouloir supprimer le contrat{" "}
              <Box component="span" fontWeight="bold">
                {contractSelected.name}
              </Box>{" "}
              ?
            </Typography>
            <ModalActions>
              <Button color="secondary" onClick={() => setDisplayModal(false)}>
                Annuler
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={async () => {
                  try {
                    await ContractService.removeContract(contractSelected.id);
                    displaySuccess("Le contrat a été supprimé");
                  } catch (e) {
                    displayError(
                      "Une erreur est survenue lors de la suppression du contrat",
                    );
                  }
                  setDisplayModal(false);
                  await getContracts();
                }}
              >
                Supprimer
              </Button>
            </ModalActions>
          </Box>
        );
      case contractActionUtils.USERS_ASSIGNED:
        return (
          <UserContractForm
            contract={contractSelected}
            users={users}
            isLoading={isLoading}
            onSubmit={async (usersSelected) => {
              setIsLoading(true);
              const usersToAssigned = users.filter(
                (u) =>
                  usersSelected.includes(u.username) &&
                  !u.contracts.includes(String(contractSelected.id)),
              );
              const usersToUnassigned = users.filter(
                (u) =>
                  !usersSelected.includes(u.username) &&
                  u.contracts.includes(String(contractSelected.id)),
              );

              if (usersToAssigned.length > 0 || usersToUnassigned.length > 0) {
                try {
                  await Promise.all(
                    usersToAssigned
                      .map((user) =>
                        UserService.updateUserContract(
                          user.id,
                          user,
                          [...user.contracts, String(contractSelected.id)].join(
                            ",",
                          ),
                        ),
                      )
                      .concat(
                        usersToUnassigned.map((user) =>
                          UserService.updateUserContract(
                            user.id,
                            user,
                            user.contracts
                              .filter((c) => c !== String(contractSelected.id))
                              .join(","),
                          ),
                        ),
                      ),
                  );
                  displaySuccess("Les utilisateurs ont été mis à jour");
                } catch (error) {
                  displayError(
                    "Une erreur est survenue lors de l'assignation d'un contrat a un utilisateur",
                  );
                }
              }

              setIsLoading(false);
              setDisplayModal(false);

              if (usersToAssigned.length > 0 || usersToUnassigned.length > 0)
                getContracts(null, true);
            }}
            onCancel={() => {
              setDisplayModal(false);
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h1">Contrats</Typography>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            setContractSelected(null);
            setModalTitle("Ajouter un contrat");
            setModalSize("sm");
            setActionType(contractActionUtils.SAVE_CONTRACT);
            setDisplayModal(true);
          }}
        >
          Ajouter un contrat
        </Button>
      </Box>

      <ContractTable
        rows={contracts}
        total={contractsTotal}
        onChange={handleQueriesChange}
        onRemove={(contract) => {
          setContractSelected(contract);
          setModalTitle("Supprimer un contrat");
          setModalSize("sm");
          setActionType(contractActionUtils.REMOVE_CONTRACT);
          setDisplayModal(true);
        }}
        onEdit={(contract) => {
          setContractSelected(contract);
          setModalTitle("Modifier un contrat");
          setModalSize("sm");
          setActionType(contractActionUtils.SAVE_CONTRACT);
          setDisplayModal(true);
        }}
        onUserAssigned={(contract) => {
          setContractSelected(contract);
          setModalTitle(
            `Assigner des utilisateurs au contrat ${contract.name}`,
          );
          setModalSize("md");
          setActionType(contractActionUtils.USERS_ASSIGNED);
          setDisplayModal(true);
        }}
      />

      <Modal
        title={modalTitle}
        open={displayModal}
        onClose={() => setDisplayModal(false)}
        size={modalSize}
        padding={0}
      >
        {renderModal()}
      </Modal>
    </>
  );
};

export default Contracts;
