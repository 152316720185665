export const KEYCLOAK_PARAMS = {
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  "auth-server-url": process.env.REACT_APP_KEYCLOAK_URL,
  "ssl-required": process.env.REACT_APP_KEYCLOAK_SSL,
  resource: process.env.REACT_APP_KEYCLOAK_RESOURCE,
  "public-client": process.env.REACT_APP_KEYCLOAK_PUBLIC_CLIENT === "true",
  "verify-token-audience":
    process.env.REACT_APP_KEYCLOAK_VERIFY_TOKEN === "true",
  "use-resource-role-mappings":
    process.env.REACT_APP_KEYCLOAK_RESOURCE_ROLE_MAPPINGS === "true",
  "confidential-port": Number.parseInt(
    process.env.REACT_APP_KEYCLOAK_CONFIDENTIAL_PORT,
    10,
  ),
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
};

export const VIEW_CONTRACTS = "view-contracts";

export const REFRESH_TOKEN_TIME = process.env.REFRESH_TOKEN_TIME || 120;
